@import "../../../../styles/_variables.css";

.main-factor-cycle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--mainFactorSizeSmall);
  height: var(--mainFactorSizeSmall);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(-1 * var(--mainFactorSizeSmall) / 2);
  margin-left: calc(-1 * var(--mainFactorSizeSmall) / 2);
  background-color: rgba(0, 0, 0, 0.24);
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  @media(--breakpoint-small-device) {
    font-size: 20px;
    width: var(--mainFactorSize);
    height: var(--mainFactorSize);
    margin-top: calc(-1 * var(--mainFactorSize) / 2);
    margin-left: calc(-1 * var(--mainFactorSize) / 2);
  }
}

.main-factor-value {
  font-size: 20px;
  font-weight: 700;
  @media(--breakpoint-small-device) {
    font-size: 34px;
  }
}

.main-metric-title {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  @media(--breakpoint-small-device) {
    font-size: 22px;
  }
}

.main-metric-value {
  font-size: 23px;
  font-weight: 700;
  @media(--breakpoint-small-device) {
    font-size: 36px;
  }
}

.competitorMetric {
  padding-left: 0;
  text-align: left;
  @media(--breakpoint-small-device) {
    padding-left: 16px;
    text-align: center;
  }
}

.speedKitMetric {
  padding-right: 0;
  text-align: right;
  @media(--breakpoint-small-device) {
    padding-right: 16px;
    text-align: center;
  }
}
