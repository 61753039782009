@import "../../../../styles/_variables.css";
@font-face {
  font-family: Poppins;
  font-weight: bold;
  src: url("/src/styles/fonts/Poppins-SemiBold.ttf");
}
.result-footer {
  background: #FFFFFF;
}

.footer-wrapper {
  
  width: 100vw;
  color: #282C39;
  display: flex;
  justify-content:center;
  align-items: center;
  text-align: left;
  margin: auto;
  padding: 20px;

  @media(--breakpoint-medium-device) {
    
    max-width:70vw;
    padding-bottom:20vh;
  }

}
.footer-content{
  display: flex;
  font-style: normal;
  gap:1rem;
  width: 100vw;
  justify-content: space-between;
  flex-direction: column;
 
  
  
  @media (--breakpoint-large-device){
    flex-direction: row;
  }
  
}
.logoCol{
  @media(--breakpoint-large-device){
    width: 35%;
  }
  
}
.column{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content-wrapper{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.2rem;
  justify-content: space-between;

}
.spacebetween{
  justify-content: space-between;
  height: 100%;
}
.marginFree{
  margin: 0;

}
.heading{
  font-size:1.6rem;
  font-weight: bold;
}

.footer-menu-column{
  width: 100%;
  @media(--breakpoint-small-device) {
    width: 50%;
  }
  @media(--breakpoint-large-device) {
    width: 25%;
  }
}

.footer-menu-header {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.footer-menu-item {
  font-size: 1.125rem;
}

.footer-line {
  background-color: #fff;
  width: 100%;
  height: 2px;
}
.pVonLogo{
  display: flex;
  align-items: left;
  margin-bottom: 0.5rem;
  
}
.logowirklich{
  height: 3.8rem;
  object-fit: contain;
  
  aspect-ratio: 5.25/1;
  @media(--breakpoint-large-device){
    width: 170px;
  }

}
.footer-icon {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  @media(--breakpoint-small-device) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  @media(--breakpoint-medium-device) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.footer-line-wrapper {
  width: 25%;
  @media(--breakpoint-medium-device) {
    width: 33.3333%;
  }
}

.footer-icon-wrapper {
  width: 50%;
  @media(--breakpoint-medium-device) {
    width: 33.3333%;
  }
}

.customer-wrapper {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  @media(--breakpoint-medium-device) {
    width: 25%;
  }
}

.customer-img {
  width: 60%;
  min-width: 130px;
  max-height: 50px;
  margin-bottom: 40px;
  @media(--breakpoint-medium-device) {
    width: 70%;
    max-height: 60px;
  }
}

.cta-wrapper {
  padding: 48px 16px 96px 16px;
  @media(--breakpoint-small-device) {
    padding: 64px 0 112px 0;
  }
}
.cta{

  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media(--breakpoint-large-device){
   padding-top: 3rem;
   padding-bottom: 3rem;
  }
  @media(--breakpoint-extra-large-device){
   
  }
}

.cta-header {
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -.025em;

  @media(--breakpoint-small-device) {
    font-size: 2.25rem;
  }
  @media(--breakpoint-medium-device) {
    font-size: 3rem;
  }
}

.cta-img {
  height: 2.5rem;
  @media(--breakpoint-small-device) {
    height: 3rem;
  }
  @media(--breakpoint-medium-device) {
    height: 4.2rem;
  }
}
.blue{
  background-color: var(--custom-purple);
}
.alignCenter{
  align-items: center;
}
.subText{
  font-size: 1.2rem;
}
.btn{
  font-weight: bold;
}
