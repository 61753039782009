.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Content .dialog {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open .dialog {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close .dialog {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(50, 50, 50, 0.95);
  z-index: 1234;
}

.modal {
  width: 100%;
  height: 100%;
  pointer-events:none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal > .close {
  color: white;
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 32px;
  line-height: 20px;
  cursor: pointer;
  pointer-events: all;
}

.modal > .dialog {
  background: white;
  padding: 24px;
  border-radius: 2px;
  pointer-events: all;
  margin: 8px;
  &.video {
    width: 100%;
    max-width: 964px;
    background: #000;
    .video-container {
      width: 100%;
      height: 0;
      padding-top: 56.25%;
      overflow: auto;
      pointer-events: auto;
      transform: translateZ(0);
      -webkit-overflow-scrolling: touch;
      iframe {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
