@import "../../../../styles/_variables.css";

.result-body {
  margin-top: -30px;
  background: #EEF2F7;
  color: #444;
  @media(--breakpoint-small-device) {
    margin-top: -70px;
  }
  @media(--breakpoint-medium-device) {
    margin-top: -100px;
  }
  @media(--breakpoint-large-device) {
    margin-top: -180px;
  }
}

.result-body-inner {
  position: relative;
  margin-top: 32px !important;
  @media(--breakpoint-small-device) {
    margin-top: 54px !important;
  }
  @media(--breakpoint-medium-device) {
    margin-top: 84px !important;
  }
  @media(--breakpoint-large-device) {
    margin-top: 164px !important;
  }
}

.result-details {
  color: #333537;
  text-align: center;
}

.scale-wrapper {
  padding: 0;
  @media(--breakpoint-small-device) {
    padding: 0 100px;
  }
  @media(--breakpoint-medium-device) {
    padding: 0 150px;
  }
  @media(--breakpoint-large-device) {
    padding: 0 200px;
  }
  @media(--breakpoint-extra-large-device) {
    padding: 0 250px;
  }
}

.box-wrapper {
  flex: 1;
  border-radius: 4px;
  white-space: nowrap;
  border: solid 1px #E1E8F0;
  background-color: #ffffff;
  padding: 40px 24px;
}

.scale {
  font-size: 16px;
  color: #ffffff;
  font-style: italic;
  font-weight: 700;
  @media(--breakpoint-small-device) {
    font-size: 22px;
  }
}

.scale-competitor {
  border-radius: 4px;
  background-color: var(--custom-dark-blue);
  padding: 9px 14px;
}

.scale-speedKit {
  border-radius: 4px 0px 0px 4px;
  background-color:var(--custom-purple);
  padding: 9px 14px;
  min-width: 75px;
  @media(--breakpoint-small-device) {
    min-width: 100px;
  }
}
.scale-safe-wrapper {
  display: flex;
  min-width: 160px;
  @media(--breakpoint-small-device) {
    min-width: 210px;
  }
}

.scale-save {
  border-radius:  0px 4px 4px 0px;
  background-color: #0152cc;
  text-align: center;
  padding: 9px 14px;
  width: 100%;
}

.text-light-grey {
  font-size: 16px;
  color: #9b9b9b;
  @media(--breakpoint-medium-device) {
    font-size: 20px;
  }
}
