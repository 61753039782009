@font-face {
    font-family: 'Axiforma';
    font-weight: 700;
    src: url('./fonts/3A22BE_0_0.eot');
    src: url('./fonts/3A22BE_0_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_0_0.woff2') format('woff2'),
    url('./fonts/3A22BE_0_0.woff') format('woff'),
    url('./fonts/3A22BE_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-weight: 700;
    font-style: italic;
    src: url('./fonts/3A22BE_1_0.eot');
    src: url('./fonts/3A22BE_1_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_1_0.woff2') format('woff2'),
    url('./fonts/3A22BE_1_0.woff') format('woff'),
    url('./fonts/3A22BE_1_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-weight: 900;
    src: url('./fonts/3A22BE_3_0.eot');
    src: url('./fonts/3A22BE_3_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_3_0.woff2') format('woff2'),
    url('./fonts/3A22BE_3_0.woff') format('woff'),
    url('./fonts/3A22BE_3_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-weight: 900;
    font-style: italic;
    src: url('./fonts/3A22BE_4_0.eot');
    src: url('./fonts/3A22BE_4_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_4_0.woff2') format('woff2'),
    url('./fonts/3A22BE_4_0.woff') format('woff'),
    url('./fonts/3A22BE_4_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-style: italic;
    src: url('./fonts/3A22BE_5_0.eot');
    src: url('./fonts/3A22BE_5_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_5_0.woff2') format('woff2'),
    url('./fonts/3A22BE_5_0.woff') format('woff'),
    url('./fonts/3A22BE_5_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-weight: 400;
    font-style: italic;
    src: url('./fonts/3A22BE_6_0.eot');
    src: url('./fonts/3A22BE_6_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_6_0.woff2') format('woff2'),
    url('./fonts/3A22BE_6_0.woff') format('woff'),
    url('./fonts/3A22BE_6_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-weight: 800;
    src: url('./fonts/3A22BE_8_0.eot');
    src: url('./fonts/3A22BE_8_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_8_0.woff2') format('woff2'),
    url('./fonts/3A22BE_8_0.woff') format('woff'),
    url('./fonts/3A22BE_8_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-weight: 800;
    font-style: italic;
    src: url('./fonts/3A22BE_A_0.eot');
    src: url('./fonts/3A22BE_A_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_A_0.woff2') format('woff2'),
    url('./fonts/3A22BE_A_0.woff') format('woff'),
    url('./fonts/3A22BE_A_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-weight: 300;
    src: url('./fonts/3A22BE_B_0.eot');
    src: url('./fonts/3A22BE_B_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_B_0.woff2') format('woff2'),
    url('./fonts/3A22BE_B_0.woff') format('woff'),
    url('./fonts/3A22BE_B_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-style: italic;
    font-weight: 300;
    src: url('./fonts/3A22BE_C_0.eot');
    src: url('./fonts/3A22BE_C_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_C_0.woff2') format('woff2'),
    url('./fonts/3A22BE_C_0.woff') format('woff'),
    url('./fonts/3A22BE_C_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-weight: 500;
    src: url('./fonts/3A22BE_D_0.eot');
    src: url('./fonts/3A22BE_D_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_D_0.woff2') format('woff2'),
    url('./fonts/3A22BE_D_0.woff') format('woff'),
    url('./fonts/3A22BE_D_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-style: italic;
    font-weight: 500;
    src: url('./fonts/3A22BE_E_0.eot');
    src: url('./fonts/3A22BE_E_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_E_0.woff2') format('woff2'),
    url('./fonts/3A22BE_E_0.woff') format('woff'),
    url('./fonts/3A22BE_E_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-weight: 400;
    src: url('./fonts/3A22BE_F_0.eot');
    src: url('./fonts/3A22BE_F_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_F_0.woff2') format('woff2'),
    url('./fonts/3A22BE_F_0.woff') format('woff'),
    url('./fonts/3A22BE_F_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-weight: 600;
    src: url('./fonts/3A22BE_10_0.eot');
    src: url('./fonts/3A22BE_10_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_10_0.woff2') format('woff2'),
    url('./fonts/3A22BE_10_0.woff') format('woff'),
    url('./fonts/3A22BE_10_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-style: italic;
    font-weight: 600;
    src: url('./fonts/3A22BE_11_0.eot');
    src: url('./fonts/3A22BE_11_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_11_0.woff2') format('woff2'),
    url('./fonts/3A22BE_11_0.woff') format('woff'),
    url('./fonts/3A22BE_11_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-weight: 200;
    src: url('./fonts/3A22BE_12_0.eot');
    src: url('./fonts/3A22BE_12_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_12_0.woff2') format('woff2'),
    url('./fonts/3A22BE_12_0.woff') format('woff'),
    url('./fonts/3A22BE_12_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Axiforma';
    font-style: italic;
    font-weight: 200;
    src: url('./fonts/3A22BE_13_0.eot');
    src: url('./fonts/3A22BE_13_0.eot?#iefix') format('embedded-opentype'),
    url('./fonts/3A22BE_13_0.woff2') format('woff2'),
    url('./fonts/3A22BE_13_0.woff') format('woff'),
    url('./fonts/3A22BE_13_0.ttf') format('truetype');
}
@font-face {
    font-family: "Poppins";
    font-weight: 400;
    src: url("./fonts/Poppins-Regular.ttf");
}
@font-face {
    font-family: "Poppins";
    src: url("./fonts/Poppins-SemiBold.ttf");
    font-weight: bold;
}
