@import "~tachyons/src/_media-queries.css";

:root {
  --spacing-none: 0;
  --spacing-extra-small: 8px;
  --spacing-small: 16px;
  --spacing-medium: 24px;
  --spacing-large: 32px;
  --spacing-extra-large: 48px;
  --spacing-extra-extra-large: 64px;
  --spacing-extra-extra-extra-large: 96px;

  --custom-dark-blue: #6b7c96;
  --custom-purple: #0568fd;
  --blue: #4a90e2;
  --white: #ffffff;

  --slick-dot-character: "•";
  --slick-dot-size: 12px;
  --slick-opacity-default: 0.1;
  --slick-opacity-on-hover: 1;
  --slick-opacity-not-active: 0.25;

  --mainFactorSize: 108px;
  --mainFactorSizeSmall: 64px;

  @custom-media --breakpoint-small-device (min-width: 576px);
  @custom-media --breakpoint-medium-device (min-width: 768px);
  @custom-media --breakpoint-large-device (min-width: 992px);
  @custom-media --breakpoint-extra-large-device (min-width: 1441px);
}
