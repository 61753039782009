.btn {
  padding-bottom: .75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-width: 2px;
  display: inline-block;
  border-radius: .25rem;
  letter-spacing: .1em;
  cursor: pointer;
  text-align: center;
  padding-top: .85rem;
  font-size: 16px;
  
  text-decoration: none;
&.btn-small {
   padding: 5px 10px;
 }
}

.btn-purple {
  border-radius: 4px;
  &:not(.btn-ghost) {
    color: white;
    background: var(--custom-purple);
    border: none;
  }
  &.btn-ghost {
    color: var(--custom-purple);
    background: transparent;
    border: 2px solid var(--custom-purple);
    &:hover {
      color: white;
      background: var(--custom-purple);
    }
  }
  &:active {
     border: none !important;
     outline: none !important;
  }
}

.btn-blue {
  border-radius: 4px;
  border: solid 3px #4a90e2;
  padding: 8px 16px;
  
  box-shadow: none;
  &:not(.btn-ghost) {
    color: white;
    background: var(--blue);
  }
  &.btn-ghost {
    color: var(--blue);
    background: transparent;
  }
}

.btn-black {
  color: rgba(0,0,0,0.7);
  background: transparent;
  border: 2px solid rgba(0,0,0,0.7);
  &:hover {
    color: rgba(0,0,0,0.8);
    background: rgba(0, 0, 0, 0.04);
  }
}

.btn-white {
  color: rgb(255, 255, 255);
  border: solid 2px #ffffff;
  
  &:hover {
    background-color: rgb(255, 255, 255);
    color: var(--custom-purple);
  }
}
.filledWhite{
  background-color: rgb(255, 255, 255);
  color: var(--custom-purple);
  &:hover{
    filter: brightness(.8);
  }
}

.btn {
  &.no-border {
    border-color: transparent;
  }
  &.no-shadow {
    box-shadow: none;
  }
}
