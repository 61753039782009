@import "../../../../styles/_variables.css";

.loading-screen {
  .device__laptop, .device__phone {
    .carousel__wrapper {
      width: 95%;
      margin: 40px 0 32px 0;
    }
    .progress__wrapper {
      width: 180px;
    }
  }

  .device__laptop {
    .carousel__wrapper {
      @media (--breakpoint-medium-device) {
        width: 70%;
      }
    }
    .progress__wrapper {
      @media (--breakpoint-small-device) {
        width: 210px;
      }
    }
  }
}
