@import "~tachyons/src/_normalize.css";
@import "~tachyons/src/_flexbox.css";
@import "~tachyons/src/_widths.css";
@import "~tachyons/src/_spacing.css";
@import "~tachyons/src/_position.css";

@import "~codemirror/lib/codemirror.css";
@import "~tachyons/src/_media-queries.css";

@import "./lib/animate.css";
@import "./lib/toggle.css";

@import "./_common.css";
@import "./_variables.css";
@import "./_reset.css";
@import "./_buttons.css";
@import "./_modal.css";
@import "./_slick.css";
@import "./_slick-theme.css";

@import "~tachyons/src/_text-align.css";
@import "~tachyons/src/_font-weight.css";
@import "~tachyons/src/_display.css";

@import "./_fonts.css";

:root {
  --dark-blue: #162939;
  --white: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  flex-grow: 1;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 1.48;
  color: color(#fff);
}

html,
body,
#speed-kit-analyzer,
#wrapper {
  min-height: 100vh;
}

#speed-kit-analyzer {
  margin: 0;
  padding: 0;
  flex-grow: 1;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 1.48;
  color: color(#fff);
  background-color: var(--custom-purple);
  @media (--breakpoint-medium-device) {
    background-color: #f4f4f4;
    background-image: none;
  }
}

#speed-kit-analyzer,
#main {
  display: flex;
  flex-grow: 1;
}

#wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#main {
  /* min-height: 100%; */
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content {
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  /* min-height: 100vh;
  @media (--breakpoint-not-small) {
    min-height: initial;
  } */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  @media (--breakpoint-small-device) {
    font-size: 36px;
  }
}
a {
  color: var(--blue);
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 1270px;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  @media (--breakpoint-medium-device) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @media (--breakpoint-large-device) {
    padding-right: 60px;
    padding-left: 60px;
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.dark-blue {
  color: var(--custom-dark-blue);
}

.purple {
  color: var(--custom-purple);
}

hr {
  height: 1px;
  border: 0;
  margin: 0;
  background-color: #dedede;
}

input {
  color: inherit;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.pr2-ns {
  padding-right: 0;
  @media (--breakpoint-small-device) {
    padding-right: 20px;
  }
}

textarea {
  height: 120px;
  resize: none;
  color: inherit;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.__react_component_tooltip.type-dark {
  background-color: rgba(0, 0, 0, 0.95);
  font-weight: 400;
}
